.react-tel-input {
  margin: 0 auto;
}
.react-tel-input .country-list .country:hover {
  background: #303345;
  border-radius: 12px;
}

.react-tel-input .country-list .country.highlight {
  background: #303345;
  border-radius: 12px;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #21232f;
  border-radius: 12px;
}
.react-tel-input .flag-dropdown.open {
  border-radius: 12px;
  background: #21232f;
}

.react-tel-input .flag-dropdown.open .selected-flag:hover {
  background: #21232f;
  border-radius: 12px;
}

.react-tel-input .country-list {
  background: #21232f;
  padding: 0;
  text-align: left;
}

.react-tel-input .selected-flag {
  min-width: 60px;
  border-radius: 12px;
  background: #21232f;
  border: none;
  margin-left: 20px;
}

.react-tel-input .selected-flag:hover {
  background: #21232f;
}

.react-tel-input .selected-flag div.arrow {
  border-top-color: white;
  margin-left: 10px;
}

.react-tel-input .selected-flag:hover {
  background: #21232f;
  border-radius: 12px;
}
.react-tel-input .selected-flag:focus {
  background: #21232f;
  border-radius: 12px;
}
.react-tel-input .selected-flag:active {
  background: #21232f;
  border-radius: 12px;
}

.react-tel-input .form-control {
  border-radius: 12px;
  background: #21232f;
  border: none;
  padding: 25px;
  /* margin-left: 90px; */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #e8ecfb;
}

.react-tel-input .flag-dropdown {
  border-radius: 12px;
  border: 1px solid #21232f;
  background: #21232f;
}
