@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/primary/Audiowide-Regular.ttf');
}
@font-face {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/secondary/ChakraPetch-Regular.ttf');
}
@font-face {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/secondary/ChakraPetch-Medium.ttf');
}
@font-face {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/secondary/ChakraPetch-SemiBold.ttf');
}
@font-face {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/secondary/ChakraPetch-Bold.ttf');
}
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/primary/Epilogue-Regular.ttf');
}
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/spaceMono/SpaceMono-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/inter/Inter-Medium.ttf');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/inter/Inter-SemiBold.ttf');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/inter/Inter-Bold.ttf');
}
